import { h, render } from 'preact';
import { produce } from 'immer';
import merge from 'lodash/merge';
import { USE_ANALYTICS, ANALYTICS_URL, VERSION } from './typing/env';
import { CONFIG_DEFAULTS } from './config/defaults';
import * as enums from './typing/enums';
import { cacher } from './utils/cacher';
import { getElement, getWidth } from './utils/dom';
import { flatten } from './utils/json';
import { isFunction } from './utils/function';
import { Events } from './utils/events';
import { initCloudinaryCore } from './utils/cloudinary';
import mediaQuery from './utils/mediaQuery';
import validate from './common/validators';
import App from './components/App/App';
import NotFound from './components/NotFound/NotFound';
import skins from './assets/skins';
import {
  prepareAssetFromTag,
  prepareByPublicId,
  prepareImageFromPublicIdString
} from './utils/prepareMediaAssets';
import { getSortedAssets } from './utils/assetHelpers';

// Polyfill pointer-events on Safari 12
import 'pepjs';

export const prepareMediaAssets = async (options, sort, sortProps, cloudinary) => {
  const mediaAssets = [];
  for (const asset of options.mediaAssets) {
    if (typeof asset === 'string') {
      const imageFromPublicId = prepareImageFromPublicIdString(asset);
      mediaAssets.push(imageFromPublicId);
    } else if (asset.tag) {
      const assets = await prepareAssetFromTag(asset, cloudinary);
      assets.length && mediaAssets.push(...assets);
    } else {
      const serializedAsset = prepareByPublicId(merge({}, asset));
      mediaAssets.push(serializedAsset);
    }
  }

  return getSortedAssets(mediaAssets, sortProps, sort);
};

const getMergedConfig = (options, initConfig) =>
  merge({}, initConfig, options.skin ? merge({}, skins[options.skin], options) : options);

const prepareConfig = async (options = {}, initConfig, cloudinaryCore) => {
  const config = getMergedConfig(options, initConfig);

  if (options.preload) {
    config.preload = options.preload;
  }

  let mediaAssets = [];

  if (options.mediaAssets) {
    mediaAssets = await prepareMediaAssets(
      options,
      options.sort !== undefined ? options.sort : config.sort,
      options.sortProps !== undefined ? options.sortProps : config.sortProps,
      cloudinaryCore
    );
  }

  if (options.skin && skins[options.skin].viewportBreakpoints) {
    config.viewportBreakpoints = skins[options.skin].viewportBreakpoints;
  }

  if (options.viewportBreakpoints) {
    config.viewportBreakpoints = options.viewportBreakpoints;
  }

  return produce(config, draft => {
    if (options.mediaAssets) {
      draft.mediaAssets = mediaAssets;
    }
  });
};

class GalleryWidget {
  constructor(options) {
    this.config = CONFIG_DEFAULTS;
    this.events = {};
    this.options = options;
    this.isValidatingElement = true;

    this.cloudinaryCore = initCloudinaryCore(merge({}, CONFIG_DEFAULTS, this.options));
    this.cacher = cacher();
    this.sendAnalytics(options);
  }

  setElement = () => {
    this.element = getElement(this.config.container);
  };

  registerEvent = (eventName, callback) => {
    if (!this.events[eventName]) {
      this.events[eventName] = [callback];
    } else {
      this.events[eventName].push(callback);
    }
  };

  registerMethod = (methodName, callback) => {
    if (!this[methodName]) {
      this[methodName] = callback;
    }
  };

  sendAnalytics(options) {
    if (USE_ANALYTICS) {
      const name = 'gallery_widget_init';
      const qs = flatten(
        produce(options, draft => {
          delete draft.mediaAssets;
          delete draft.container;
        })
      );
      fetch(`${ANALYTICS_URL}/${name}?${qs}&pgw_version=${VERSION}`);
    }
  }

  prepareElement() {
    this.isValidatingElement = true;
    this.setElement();

    return new Promise(resolve => {
      if (this.element && getWidth(this.element) > 0) {
        resolve(true);
      } else {
        setTimeout(() => {
          resolve(this.element && getWidth(this.element) > 0);
        }, 250);
      }
    }).finally(() => {
      this.isValidatingElement = false;
    });
  }

  renderApp(isNewMediaAssets, breakpointConfig = {}) {
    render(
      <App
        {...merge({}, this.config, breakpointConfig)}
        cloudinaryCore={this.cloudinaryCore}
        events={this.events}
        cacher={this.cacher}
        isNewMediaAssets={isNewMediaAssets}
        registerMethod={this.registerMethod}
      />,
      this.element
    );
  }

  async render(initOptions, isNewMediaAssets = false) {
    const options = initOptions || this.options;
    this.config = await prepareConfig(options, this.config, this.cloudinaryCore);
    this.isValidElement = await this.prepareElement();

    if (this.isValidatingElement) {
      return;
    }

    if (!this.isValidElement) {
      throw new Error(
        'Element is probably not rendered to the DOM - something with your layout or CSS definition'
      );
    }

    this.mediaQueryManager?.destroy();

    if (!this.config.mediaAssets.length) {
      render(<NotFound msg="No media assets available for preview" />, this.element);
    } else if (this.config.viewportBreakpoints.length) {
      this.mediaQueryManager = new mediaQuery(this.config.viewportBreakpoints, breakpointConfig => {
        // check if element exist - since in some cases if element is not exist media wueries are still running and can stuck thr page - happend when not using correctly  - its a sfaety function - need to check perfpormance
        const isElement = document.body.contains(this.element);
        if (isElement) {
          this.renderApp(isNewMediaAssets, breakpointConfig);
        } else if (this.mediaQueryManager) {
          this.mediaQueryManager.destroy();
        }
      });
    } else {
      this.renderApp(true);
    }

    return { ok: true };
  }

  async update(options = {}) {
    // checking if cloudName exist - to prevent update before first render - since missing all props
    if (!this.config.cloudName) {
      console.warn(
        'Product Gallery:: missing cloudName, are you calling the update method before calling the render method?'
      );

      return { ok: false };
    }

    if (validate(options)) {
      return this.render(options, !!options.mediaAssets);
    }

    return { ok: true };
  }

  focus() {
    this.element.focus();
  }

  hide() {
    this.element.style.display = 'none';
  }

  destroy() {
    if (this.element instanceof HTMLElement) {
      render(null, this.element);
    }

    if (this.mediaQueryManager) {
      this.mediaQueryManager.destroy();
    }
  }

  on(eventName, callback) {
    if (
      isFunction(callback) &&
      Object.values(Events).filter(event => {
        return event.name === eventName;
      }).length === 1
    ) {
      this.registerEvent(eventName, callback);
    }
  }
}

(win => {
  const cloudinary = {
    ...(win.cloudinary || {}),
    galleryWidget: options => {
      if (validate(options)) {
        return new GalleryWidget(options);
      }
    },
    galleryWidgetInfo: {
      VERSION,
      ENUMS: enums,
      DEFAULTS: CONFIG_DEFAULTS
    }
  };
  win.cloudinary = cloudinary;
})(self);
