import React, { useEffect, useState } from 'react';
import './ModalPlan.scss';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { functions, httpsCallable } from "../../firebase.config";
import Button from '../button/Button';

import singlePlan from '../../assets/icons/single-plan.png';
import secondPlan from '../../assets/icons/second-plan.png';
import thirdPlan from '../../assets/icons/third-plan.png';
import fourthPlan from '../../assets/icons/fourth-plan.png';
import { useAuth } from '../../contexts/auth.context';
import { getAllStripeSubscriptions } from '../../firebase.service';

function ModalPlan({ pageData, activeLicences }) {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    const [userUniqueID, setUserUniqueID] = useState(null);

    const { userData } = useAuth();

    useEffect(() => {
        console.log('user unique ID', userData.uid);
        setUserUniqueID(userData.uid);
    }, [userData]);

    useEffect(() => {
        console.log('licences', activeLicences); // licences (2) ['prod_QDXB7QmBXfkHMj', 'prod_QGvJllXxu5hVS7']
    }, [activeLicences]);

    const stripePromise = loadStripe('pk_live_51OXkuvAjE26gLjslpPhLi7qxRvPHNRWAakBQXFVpiYmS7ZMJIBNwLVdL86l1AZmjE7MCmgU95IgxhZsisE1sM79800wvwh3WHe');

    const productPricesPhoto = [
        { id: 'price_1Pd1ohAjE26gLjslfRdOBKYB', ref: 'prod_QTzoyC91AxIvhG', price: 1.49, name: '+50 photos', icon: singlePlan },
        { id: 'price_1Pd1pCAjE26gLjsl0b5T9lHd', ref: 'prod_QTzoXBV5frQ4Jx', price: 2.49, name: '+100 photos', icon: secondPlan },
        { id: 'price_1Pd1pTAjE26gLjslt7ZOE667', ref: 'prod_QTzpFybNWLcKpQ', price: 2.99, name: '+200 photos', icon: fourthPlan },
        { id: 'price_1Pd1plAjE26gLjsl5yOsX39o', ref: 'prod_QTzpafcO6f1I9q', price: 5.99, name: '+500 photos', icon: thirdPlan },
    ]

    // const productPricesVideo = [
    //     { id: 'price_1PQNT9AjE26gLjslT6zNZM7s', ref: 'prod_QGvJllXxu5hVS7', price: 2, name: '+5 videos', icon: singlePlan },
    //     { id: 'price_1PQNTxAjE26gLjsluvMnWYmB', ref: 'prod_QGvKLbSqnC5Q7R', price: 3.5, name: '+10 videos', icon: secondPlan },
    //     { id: 'price_1PQRLHAjE26gLjslmpnjSQ1f', ref: 'prod_QGzJhLC4kfDPrS', price: 5, name: '+20 videos', icon: fourthPlan },
    //     { id: 'price_1PQRLwAjE26gLjslKpYcCvpD', ref: 'prod_QGzKzE4ixZWLI6', price: 10, name: '+50 videos', icon: thirdPlan }
    // ]

    // +50 photos = prod_QTzoyC91AxIvhG
    // +100 photos = prod_QTzoXBV5frQ4Jx
    // +200 photos = prod_QTzpFybNWLcKpQ
    // +500 photos = prod_QTzpafcO6f1I9q

    const handleClick = async () => {
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
        const response = await stripePromise;
        console.log("Stripe response:", response);
        try {
            const priceIds = selectedProducts; // Récupère les produits sélectionnés
            console.log("Sending priceIds:", priceIds); // Log des priceIds envoyés

            const result = await createCheckoutSession({ priceIds, userId: userUniqueID, pageId: pageData.id, userEmail: userData.email });
            console.log("Result from cloud function:", result); // Log de la réponse

            const sessionId = result.data.id;

            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error("Stripe error:", error);
            }
        } catch (error) {
            console.error("Function call error:", error);
        }
    };

    const handleProductSelection = (priceId, price) => {
        setSelectedProducts(prevSelectedProducts => {
            let updatedProducts;
            if (prevSelectedProducts.includes(priceId)) {
                updatedProducts = prevSelectedProducts.filter(id => id !== priceId);
                setTotalPrice(prevTotal => prevTotal - price);
            } else {
                updatedProducts = [...prevSelectedProducts, priceId];
                setTotalPrice(prevTotal => prevTotal + price);
            }
            return updatedProducts;
        });
    };

    return (
        <div className='modal-plan-container'>
            {/* <div className='mb-10'>
                <b>Choisir les options</b>
            </div> */}
            {/* <span className='title'>Photos pack</span> */}
            <div className='modal-plan-body'>
                {productPricesPhoto.map((product, index) => (
                    <div className="modal-plan" key={index}>
                        <label>
                            {activeLicences && activeLicences.includes(product.ref) ? (
                                <span className='active'>
                                    {/* <img src={product.icon} style={{ filter: 'invert(0)'}} /> */}
                                    <b>{product.name}</b>
                                    Déjà actif
                                </span>
                            ) : (
                                <>
                                    <input
                                        type="checkbox"
                                        value={product.id}
                                        onChange={(e) => handleProductSelection(e.target.value, product.price)}
                                    />
                                    <span>
                                        {/* <img src={product.icon} /> */}
                                        {product.name}
                                        {/* <small><b>{product.price}€</b>/mois</small> */}
                                    </span>
                                </>
                            )}
                        </label>
                    </div>
                ))}

            </div>

            {/* <span className='title'>Vidéos</span>
            <div className='modal-plan-body'>
                {productPricesVideo.map((product, index) => (
                    <div className="modal-plan" key={index}>
                        <label>
                            {activeLicences && activeLicences.includes(product.ref) ? (
                                <span className='active'>
                                    <b>{product.name}</b>
                                    Déjà actif
                                </span>
                            ) : (
                                <>
                                    <input
                                        type="checkbox"
                                        value={product.id}
                                        onChange={(e) => handleProductSelection(e.target.value, product.price)}
                                    />
                                    <span>
                                        <img src={product.icon} />
                                        {product.name}
                                        <small><b>{product.price}€</b>/mois</small>
                                    </span>
                                </>
                            )}
                        </label>
                    </div>
                ))}
            </div> */}
            <Elements stripe={stripePromise}>
                {totalPrice !== 0 &&
                    <Button text={`Valider mes options pour ${totalPrice.toFixed(2)}€/mois`} theme="primary" onClick={handleClick} />
                }
            </Elements>
        </div>
    );
}

export default ModalPlan;
