import { h, Component } from 'preact';
import { CONFIG_DEFAULTS } from '../../config/defaults';
import { bindSelectorsToConfig } from '../../common/selectors';
import { createEvents } from '../../utils/events';
import { Context } from '../../common/context';
import Gallery from '../Gallery/Gallery';

class App extends Component {
  constructor(props) {
    super(props);

    this.setConfig(props);
    this.setCldCore(props);
    this.setEvents(props);
  }

  componentDidUpdate(prevProps) {
    // need to force update because when changing between axis - the container width or height is updated after carousel has been rendered - causing the wrong calculations
    if (prevProps.carouselLocation !== this.props.carouselLocation) {
      this.forceUpdate();
    }
  }

  setConfig = props => {
    const config = {
      ...CONFIG_DEFAULTS,
      ...props
    };
    delete config.events;
    delete config.cacher;
    this.config = bindSelectorsToConfig(config, props.cloudinaryCore);
  };

  setCldCore = props => {
    this.cldCore = props.cloudinaryCore;
  };

  setEvents = props => {
    this.events = createEvents(props.events, this.config.selectAnalytics());
  };

  render(props) {
    this.setConfig(props);
    const context = {
      cloudinary: this.cldCore,
      config: this.config,
      events: this.events,
      cacher: props.cacher
    };
    /* In order to use context inside tests and not duplicate code */
    const isChildren = !!(this.props.children && this.props.children.length);

    return (
      <Context.Provider value={context}>
        {!isChildren ? (
          <Gallery
            items={props.mediaAssets}
            index={props.isNewMediaAssets ? context.config.selectStartIndex() : null}
            registerMethod={props.registerMethod}
          />
        ) : null}
      </Context.Provider>
    );
  }
}

export default App;
